import React, { Component, createContext } from 'react';
import { baseUrl, hostUrl } from '../config';
import { useUrlManagerCleanUrl } from "../utils/UrlManager";

export const AdminUserContext = createContext(null);

class AdminUserContextProvider extends Component {

    componentDidMount() {
        this.fetchProductTreeData();
        this.fetchCountries();
        this.fetchActivityMonitorTypes();
        this.setupStripeIntervalCounts();
        this.setDefaults();
        this.fetchUserRols();
        this.fetchProductData();
    }

    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            addressTypes: [
                { id: 'shipping', name: 'Shipping' },
                { id: 'billing', name: 'Billing' }
            ],
            orderStatuses: [
                { id: "draft", name: 'Draft' },
                { id: "paid", name: 'Paid' }
            ],
            orderStates: [
                { id: 0, name: 'Inactive' },
                { id: 1, name: 'Active' }
            ],
            currencies: [
                { id: 'gbp', name: '£ - British Pound', symbol: '£' },
                { id: 'eur', name: '€ - Euro', symbol: '€' },
            ],
            subscriptionDiscountTypes: [
                { id: 'fixed', name: 'Fixed' },
                { id: 'percentage', name: 'Percentage' },
                { id: 'fixed_amount', name: 'Fixed Amount' }
            ],
            productTypes: [
                { id: 0, name: 'Parent' },
                { id: 1, name: 'Child' },
                { id: 2, name: 'Single' },
            ],
            activityMonitorTypes: [],
            stripeIntervals: [
                { id: 'week', name: 'Weekly' },
                { id: 'month', name: 'Monthly' },
                { id: 'year', name: 'Yearly' },
            ],
            stripeIntervalYearCount: [],
            stripeIntervalMonthCount: [],
            stripeIntervalWeekCount: [],
            productTreeData: [],
            adminUserRoles: [],
            showActionOverlay: false,
            productsData: []
        };
    }

    fetchProductData() {
        const token = localStorage.getItem("auth");
        let url = hostUrl + 'barber/subscription/product/loader';
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            // body: JSON.stringify({ id: id }),
            headers: new Headers({
                'Content-Type' : 'application/json',
                'Token':  token,
                'Barbershop' : 'admin',
            })
        });
        return fetch(request)
            .then(response => {

                return response.json();
            })
            .then(data => {
                // console.log(data);
                if (data.success === true) {
                    this.setState({
                        productsData: data.data
                    });
                    return data.data;
                }
                return [];
            })
            .catch (error => {
                console.log(error);
            })
    }

    hideShowActionOverlay(state) {
        this.setState({
            showActionOverlay: state
        });
    }

    fetchUserRols() {
        const token = localStorage.getItem('auth');
        let url = hostUrl + 'barber/admin/user/roles';
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json',
            })
        });
        fetch(request)
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({
                    adminUserRoles: data
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    setDefaults() {

        let data = {
            'sales_subscriptions': [
                {
                    "key": "customer",
                    "value": "/api/customer_entities/"
                },
                {
                    "key": "quote",
                    "value": "/api/sales_quotes/"
                },
                {
                    "key": "shipping_method",
                    "value": "/api/sales_shipping_methods/"
                },
                {
                    "key": "addresses",
                    "value": "/api/sales_order_addresses/"
                },
                {
                    "key": "shippingMethod",
                    "value": "/api/sales_shipping_methods/"
                },
                {
                    "key": "products",
                    "value": "/api/catalog_product_entities/"
                },
                {
                    "key": "coupons",
                    "value": "/api/sales_coupons/"
                }
            ],
            'customer_entities': [
                {
                    "key": "subscription",
                    "value": "/api/sales_subscriptions/"
                }
            ]
        }
        localStorage.setItem('stored_data', JSON.stringify(data));
    }


    setupStripeIntervalCounts() {
        // loop the interval counts
        // debugger;;
        let tmp = {};
         tmp['year'] = [
             { id: '1', name: '1' },
             { id: '2', name: '2' },
             { id: '3', name: '3' },
         ];
         let months = [];
         let weeks = [];
         tmp['week'] = [];
         let monthCounter = 1;
         for (let i = 1; i <= 156; i++) {
             if (monthCounter <= 36) {
                 months.push({ id: i.toString(), name: i.toString() });
                 monthCounter++;
             }
             weeks.push({ id: i.toString(), name: i.toString() });
         }

         this.setState({
             stripeIntervalYearCount: [
                 { id: '1', name: '1' },
                 { id: '2', name: '2' },
                 { id: '3', name: '3' },
             ],
             stripeIntervalMonthCount: months,
             stripeIntervalWeekCount: weeks,
         });
    }

    fetchProductTreeData() {
        // debugger;
        let url = hostUrl + 'barber/product/tree/data';
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        })

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {

                }
                return response.json();
            })
            .then(data => {
                if (data && data.status === 'success') {
                    this.setState({
                        productTreeData: data.treeData
                    })
                }
            })
            .catch(error => {
                console.log(error);
                return Promise.reject()
                    .catch(error => {
                        console.log(error);
                    })
            });
    }

    getStripeMode() {
        return process.env.STRIPE_MODE;
    }
    fetchActivityMonitorTypes() {
        let url = hostUrl + 'barber/activity/monitor/types';
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        })

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {

                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    let types = data;
                    types.push({id: ' status_change', name: "Status Change"});
                    this.setState({
                        activityMonitorTypes: types
                    })
                }
            })
            .catch(error => {
                console.log(error);
                return Promise.reject()
                    .catch(error => {
                        console.log(error);
                    })
            });
    }

    async fetchPaymentLink(subscriptionId, activeLinkId = null) {

        let url = "subscriptions/payment/link/fetch?subscription_id=" + subscriptionId;
        url = useUrlManagerCleanUrl({ url: url });
        if (activeLinkId) {
            url += '&link_id=' + activeLinkId;
        }
        url = useUrlManagerCleanUrl({ url: url });

        const request = new Request(hostUrl + url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
            }
            const data = await response.json();
            if (data) {
                if (data.success) {
                    return data;
                }
                if (data.error) {
                    return data;
                }
            }
        } catch (error) {
            console.error(error);
        }

    }

    async cancelPaymentLink(activeLinkId) {

        if (activeLinkId == null) {
            return null;
        }
        let url = "subscriptions/payment/link/cancel?link_id=" + activeLinkId;
        url = useUrlManagerCleanUrl({ url: url });

        const request = new Request(hostUrl + url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
            }
            const data = await response.json();
            if (data) {
                if (data.success) {
                    return data;
                }
                if (data.error) {
                    return data;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async changeSubscriptionDate(id, date) {
        // debugger;
        let url = hostUrl + "subscriptions/admin/update/date?subscription_id=" + id + "&date=" + date;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "PATCH",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {

            }
            const data = await response.json();
            if (data) {
                if (data.success) {
                    return true;
                }
                if (data.error) {
                    return data;
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    async pauseSubscription(id) {
        // debugger;
        let url = hostUrl + "subscriptions/admin/update/pause?subscription_id=" + id ;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
            }
            const data = await response.json();
            if (data) {
                if (data.success) {
                    return true;
                }
                if (data.error) {
                    return data.error_message;
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    async resumeSubscription(id, date) {
         // debugger;
        let url = hostUrl + "subscriptions/admin/update/resume?subscription_id=" + id;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request( url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });
        try {

            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
            }
            const data = await response.json();
            if (data) {
                if (data.success) {
                    return true;
                }
                if (data.error) {
                    return data.message;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async cancelSubscription(id) {
        let url = hostUrl + "subscriptions/admin/update/cancel?subscription_id=" + id;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });
        try {

            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
            }
            const data = await response.json();
            if (data) {
                if (data.success) {
                    return true;
                }
                if (data.error) {
                    return data;
                }
             }

        } catch (error) {

        }
        return false;
    }

    /**
     * Load Base Country List
     */
    fetchCountries() {

        const request = new Request(baseUrl + 'countries?itemsPerPage=500', {
            method: 'GET',
            headers: new Headers({
                'Content-Type' : 'application/json'
            })
        });
        fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                let responseData = [];
                if (Array.isArray(data['hydra:member'])) {
                    const countries = data['hydra:member'];
                    for (var i = 0; i < countries.length; i++ ) {
                        let country = countries[i];
                        let tmp = {
                            id: country['code'],
                            name: country['name']
                        }
                        responseData.push(tmp);
                    }
                }
                if (responseData.length > 0) {
                    this.setState({
                        countryList: responseData
                    });
                }
                return responseData;
            })
            .catch(error => {
                return Promise.reject()
                    .catch(error => {
                        console.log(error);
                    })
            });
    }

    async flushDefaultCache() {
        let token = localStorage.getItem('auth');
        let url = hostUrl + "barber/cache/flush/default";
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (typeof data !== "undefined" && data.success === true) {
                    return true
                }
                return false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    async flushRedisCache() {
        let token = localStorage.getItem('auth');
        let url = hostUrl + "barber/cache/flush/redis";
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (typeof data !== "undefined" && data.success === true) {
                    return true
                }
                return false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    async flushPoolsCache() {
        let token = localStorage.getItem('auth');
        let url = hostUrl + "barber/cache/flush/pools";
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (typeof data !== "undefined" && data.success === true) {
                    return true
                }
                return false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    async fetchSurveyAnalytics(id) {
        let token = localStorage.getItem('auth');
        let url = hostUrl + "barber/survey/analytics?id=" + id;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (typeof data !== "undefined" && data.success === true) {
                    return data.data
                }
                return false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    async fetchSurveyEditJson(surveyId) {
        let token = localStorage.getItem('auth');
        let url = hostUrl + "barber/survey/json?id=" + surveyId;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (typeof data !== "undefined" && data.success === true) {
                    return data.data
                }
                return false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    async gdprDelete(customerId) {
        let token = localStorage.getItem('auth');
        let url = hostUrl + 'barber/customer/gdpr/delete?id=' + customerId;
        url = useUrlManagerCleanUrl({ url: url });
        const request = new Request(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Token':  token,
                'Barbershop' : 'admin'
            })
        });
        return fetch(request)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (typeof data !== "undefined" && data.success === true) {
                    return true
                }
                return false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <AdminUserContext.Provider value={{
                ...this.state,
                getStripeMode: this.getStripeMode.bind(this),
                pauseSubscription: this.pauseSubscription.bind(this),
                resumeSubscription: this.resumeSubscription.bind(this),
                cancelSubscription: this.cancelSubscription.bind(this),
                changeSubscriptionDate: this.changeSubscriptionDate.bind(this), // UNUSED - TO BE REMOVED
                fetchActivityMonitorTypes: this.fetchActivityMonitorTypes.bind(this),
                fetchPaymentLink: this.fetchPaymentLink.bind(this),
                hideShowActionOverlay: this.hideShowActionOverlay.bind(this),
                flushDefaultCache: this.flushDefaultCache.bind(this),
                flushRedisCache: this.flushRedisCache.bind(this),
                flushPoolsCache: this.flushPoolsCache.bind(this),
                fetchSurveyAnalytics: this.fetchSurveyAnalytics.bind(this),
                fetchSurveyEditJson: this.fetchSurveyEditJson.bind(this),
                gdprDelete: this.gdprDelete.bind(this),
            }}>
                { this.props.children }
            </AdminUserContext.Provider>
        );
    }

}

export default AdminUserContextProvider;