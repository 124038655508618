import React, { useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required
} from 'react-admin';

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

const choices = [
    { id: 'pause', name: 'Pause' },
    { id: 'cancel', name: 'Cancel' },
    { id: 'consultation', name: 'Consultation' },
];

export const SurveyCreate = () => {

    return (
        <Create redirect="edit">
            <SimpleForm>
                <TextInput source="name" name="name" validate={required()} />
                <TextInput source="code" name="code" validate={required()} />
                <SelectInput name="type" source="type" choices={ choices } validate={required()} />
                <BooleanInput source="enabled" name="enabled" validate={required()} />
            </SimpleForm>
        </Create>
    );
}