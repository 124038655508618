import React, { useState } from 'react';
import {
    TextField,
    List,
    Datagrid,
    BooleanField,
    SearchInput,
    EditButton, usePermissions, TopToolbar, CreateButton, ExportButton
} from 'react-admin';


const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

const ListActions = () => {
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            { (permissions && (permissions.includes('ROLE_SUPER_ADMIN') || permissions.includes('ROLE_COMMERCE')) ) &&
                <CreateButton />
            }
            <ExportButton />
        </TopToolbar>
    );
}

export const SurveysList = () => {

    return (
        <List
            actions={ <ListActions /> }
            title="Surveys"
            filters={ customerFilters }
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid  bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="code" />
                <BooleanField source="enabled" />
                <EditButton />
            </Datagrid>
        </List>
    );
}