import React from 'react';
import {
     DateField,
    TextField,
    useRecordContext,
    Datagrid,
    ReferenceManyField,
    ShowButton,
    Pagination
} from 'react-admin';

const excludeKeys = [
    'next-order-date'
];

const ExpandPanel = () => {
    const record = useRecordContext();
    let valString = "";
    let data = record.json;
    let keys = Object.keys(data);

    for(let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (!excludeKeys.includes(key)) {
            let datum = data[key];
            if (datum !== '') {
                key = key.replaceAll('-', ' ');
                key = key.charAt(0).toUpperCase() + key.slice(1);
                valString += key + ': ' + datum + "\n\n";
            }

        }
    }

    return (
        <pre>
            { valString }
        </pre>
    );
}

export const PauseCancelReasons = (props) => {
    const record = useRecordContext();

    return (
        <ReferenceManyField
            source="surveyForms"
            reference="survey_forms"
            target="id"
            label="Survey Results"
            pagination={ <Pagination /> }
            sort={ { field: 'id', order: 'DESC' } }
        >
            <Datagrid
                bulkActionButtons={false}
                expand={ <ExpandPanel /> }
            >
                <TextField source="id" />
                <TextField source="surveyType" sx={ { textTransform: 'capitalize' } } />
                <DateField source="createdAt" showTime={ true } />
                <ShowButton target="_blank" />
            </Datagrid>
        </ReferenceManyField>
    );
}