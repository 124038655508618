import React, { useState } from 'react';
import {
    TextField,
    List,
    Datagrid,
    DateField,
    SearchInput,
    EditButton
} from 'react-admin';


const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

export const SurveyList = () => {

    return (
        <List
            title="Survey Results"
            filters={ customerFilters }
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid  bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="email_address" />
                <TextField source="external_id" />
                <TextField source="ip_address" />
                <DateField source="created_at" showTime={true} />
                <EditButton />
            </Datagrid>
        </List>
    );
}