import React, { useContext, useState, useEffect } from 'react';
import {
    Edit,
    required,
    SimpleForm,
    TextInput,
    useRecordContext,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import {
    Box,
    CircularProgress,
    Grid,
    Stack,
} from '@mui/material';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

const SurveyResults = () => {
    const context = useContext(AdminUserContext);
    const [loading, setLoading] = useState(true);
    const [surveyJson, setSurveyJson] = useState({});
    const record = useRecordContext();

    useEffect(() => {
        ( async () => {
            context.fetchSurveyEditJson(record.survey )
                .then(response => {
                    setSurveyJson(response);
                    setLoading(false);
                })
        } )();
    }, [])

    return (
        <>
            { loading &&
                <Box sx={ { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    <CircularProgress />
                </Box>
            }

            { !loading &&
                <>
                    <JsonSchemaForm schema={{
                        type: 'object',
                        properties: {
                            json: surveyJson,
                        }
                    }} />

                </>
            }
        </>
    );
}

export const SurveyEdit = (props) => {
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} >
                <Edit redirect="false" title=" " >
                    <SimpleForm>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack spacing={2}>
                                    <TextInput source="email_address" name="email_address" validate={required()}/>
                                    <TextInput source="external_id" name="external_id" validate={required()} />
                                    <TextInput source="ip_address" name="ip_address" />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <ReferenceInput
                                    source="customer"
                                    reference="customer_entities"
                                    name="customer"
                                    enableGetChoices={({ q }) => q && q.length >= 2}
                                >
                                    <AutocompleteInput label="Customer" optionText="email_address" name="customer" />
                                </ReferenceInput>
                                <ReferenceInput
                                    source="subscription"
                                    reference="sales_subscriptions"
                                    name="subscription"
                                    enableGetChoices={({ q }) => q && q.length >= 2}
                                    >
                                    <AutocompleteInput label="Subscription" optionText="id" name="subscription" />
                                </ReferenceInput>
                                <ReferenceInput
                                    source="survey"
                                    reference="surveys"
                                    name="survey"
                                    enableGetChoices={({ q }) => q && q.length >= 2}
                                >
                                    <AutocompleteInput label="Survey" optionText="name" name="survey" />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Edit>
            </Grid>
            <Grid item xs={12}>
                <Edit redirect='false' >
                    <SurveyResults />
                </Edit>
            </Grid>
        </Grid>
    );
}
