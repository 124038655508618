import React, { useState, useEffect, useContext } from 'react';
import 'survey-analytics/survey.analytics.min.css';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics'
import { AdminUserContext } from "../../contexts/AdminUserContextProvider";
import {
    Box,
    CircularProgress
} from "@mui/material";

const loadingStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};

export const Analytics = (props) => {
    const surveyId = props.id;
    const [loading, setLoading] = useState(true);
    let divId = "surveyVizPanel-" + surveyId;
    const context = useContext(AdminUserContext);
    const [survey, setSurvey] = useState(null);
    const [surveyResults, setSurveyResults] = useState([]);
    const [vizPanel, setVizPanel] = useState(null);
    let surveyModel ;

    if (!survey) {
        let t = context.fetchSurveyAnalytics(surveyId)
            .then(response => {
                console.log(response);
                // console.log(JSON.parse(response));
                surveyModel = new Model(response.survey);
                setSurvey(surveyModel);
                setSurveyResults(response.data);

                // panel.render(divId);
                setLoading(false);
            });
    }

    if (!!survey && !vizPanel) {

        const panel = new VisualizationPanel(
            survey.getAllQuestions(),
            surveyResults,
            {
                allowHideQuestions: true
            }
        );
        panel.showToolbar = false;
        setVizPanel(panel);
    }

    useEffect(() => {
        if (vizPanel) {
            vizPanel.render(divId);
        }
        return () => {
            if (document.getElementById(divId)) {
                document.getElementById(divId).innerHTML = "";
            }
        }

    }, [vizPanel]);

    return (
        <div>
            { loading &&
                <Box sx={ loadingStyles } >
                    <span>{ "Loading Analytics..." }</span>
                    <CircularProgress />
                </Box>

            }
            <div id={divId}/>

        </div>
    );
}