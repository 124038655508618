import React, { useState, useEffect, useContext } from 'react';
import 'survey-analytics/survey.analytics.min.css';
import {
    AutocompleteArrayInput,
    BooleanField,
    Confirm,
    DateField, DateInput, FormTab,
    ReferenceField,
    SaveButton,
    SelectField,
    SelectInput,
    SimpleForm,
    TextField,
    Toolbar,
    useDelete,
    usePermissions,
    useRecordContext,
    useUpdate,
    WithRecord,
    useNotify,
    useRefresh,
    useGetList, Title
} from 'react-admin'
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics'
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    LinearProgress,
    Grid,
    Stack,
    Tab,
    Tabs,
    Link,
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { Analytics } from "./components/Analytics";

export const SurveyAnalytics = () => {
    const context = useContext(AdminUserContext);
    const [value, setValue] = useState(0);
    const { data, total, isPending, error } = useGetList(
        'surveys',
        {}
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function allyProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    function Panel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={ value !== index }
                id={ `setting-tabpanel-${ index }` }
                className={ `setting-tabpanel-${ index }` }
                aria-labelledby={ `simple-tab-${ index }` }
                { ...other } >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        { children }
                    </Box>
                )}
            </Box>
        );
    }

    if (isPending || !data) {
        return (
            <Box sx={ {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            } }>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Title title={ "Survey Analytics" } />
            <Box className="tab-tiles" sx={ { width: '100%' } }>
                <Tabs value={ value } onChange={ handleChange } aria-label="company tabs">
                    { data.map((record, index) => {

                        return (
                            <Tab key={ "survey_analytics_tab_" + index }
                                label={ record.name }
                                 { ...allyProps(index) }
                            />
                        );
                    }) }
                </Tabs>
            </Box>
            <Box>
                { data.map( (record, index) => {
                    return (
                        <Panel value={ value } key={ "survey_analytics_panel_" + index } index={ index }>
                            <Analytics
                                id={ record.id }
                            />
                        </Panel>

                    );
                } ) }
            </Box>
        </Box>
    );

}